import { Form, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import promotionService from '../../../services/config/promotion.service';

const ImportPromoTicketTypeAvailability = ({ park_id, form, }) => {
    const [state, setState] = useState({
        loading : false,

        // data mapping
        columns : [],
        datas : [],

        ticket_user_types : [],
    })

    const [updated, setUpdated] = useState([]); // to set state on changed toggle

    const toggleChanges = (is_applicable, ticket_applicable_user_id) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.ticket_applicable_user_id == ticket_applicable_user_id);
        if(exisiting_updated_date){
            // update existing
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            // push new
            updated_data?.push({ is_applicable, ticket_applicable_user_id, });
        }

        setUpdated(updated_data);
        // update back to form
        form.setFieldsValue({ ticket_applicable_users : updated_data, });
    }

    const fetchPromoCodeTicketApplicableUser = async () => {
        setState(state => ({...state, loading : true, }));
    
        let columns = [];
        let datas = [];
        let get_ticket_user_types = [];
        const promoCodeTicketApplicableUserResponse = await promotionService?.getPromoCodeApplicableTicketUser(park_id, {

        });

        const { ticket_types, ticket_user_types } = promoCodeTicketApplicableUserResponse;
        get_ticket_user_types = ticket_user_types;
        columns = await generateColumns(ticket_user_types);
        datas = await generateDataSource(ticket_types);

        setState(state => ({...state, loading : false, columns, datas, ticket_user_types : get_ticket_user_types }))
    }

    const generateColumns = async (ticket_user_types) => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // map data
        for(const ticket_user_type of ticket_user_types){
            const { name, ticket_user_type_id, } = ticket_user_type;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, ticket_applicable_user_id } = applicable[ticket_user_type_id] ?? {};
                    // from state
                    const checked_toggle = updated?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id) ?? false;

                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        <Switch
                                        onChange={(e) => toggleChanges(e, ticket_applicable_user_id)}
                                        size='small'
                                        defaultChecked={checked_toggle?.is_applicable}
                                        />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateDataSource = async (ticket_types) => {
        // map data
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id } = x;
            const { ticket_user_types } = park_applicable_ticket_types ?? {};

            let applicable = {};
            for(const ticket_user_type of ticket_user_types){
                const { TicketApplicableUser : ticket_applicable_user } = ticket_user_type;
                applicable[ticket_user_type?.ticket_user_type_id] = {
                    is_applicable : ticket_user_type?.is_applicable,
                    ticket_applicable_user_id : ticket_applicable_user?.ticket_applicable_user_id,
                };
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    useEffect(() => {
        fetchPromoCodeTicketApplicableUser();
    }, [park_id])

    return (
        <>
            <Table
            loading={state?.loading}
            scroll={{x : 600,}}
            pagination={false}
            className='hub-table bordered'
            dataSource={state?.datas}
            columns={state?.columns}
            />
        </>
    );
}

export default ImportPromoTicketTypeAvailability;