import React, { useEffect, useState } from 'react';
import ImportPromoForm from './import_promo_form';
import { Button, Form, Input, List, Radio, Space } from 'antd';
import { CaretDownFilled, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import promotionService from '../../../services/config/promotion.service';

const ImportPromoModal = ({ form, }) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        parks : [],
        selected_park_id : null,
    })

    const fetchAuthParks = async () => {
        const parkResponse = await parkService?.getParkByAuth();
        const { parks, } = parkResponse;
        setState(state => ({...state, parks : parks, }));
    }

    const onParkChange = (park_id) => {
        setState(state => ({...state, selected_park_id : park_id, }));
        form.setFieldsValue({ park_id : park_id, });
    }
    
    const downloadTemplate = async () => {
        try{
            const downloadTemplateResponse = await promotionService?.downloadPromotionCodeImportTemplate();

            // Create a blob URL
            const blob = new Blob([downloadTemplateResponse], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            // const blob = await downloadTemplateResponse.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Import Promotion Code Template.xlsx'; // File name from backend
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }catch(err){
            console.error('Download error:', err);
        }
    }

    useEffect(() => {
        fetchAuthParks();
    }, [])

    return (
        <>
            <div className='filter-panel'>
                <div style={{display:'flex', alignItems:'center', }}>
                    <div style={{textAlign:'start',}}>
                        <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                            <Form.Item name={"park_id"} hidden />
                            <FilterBullet
                            popover={
                                <>
                                    <div>
                                        <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                    </div>
                                    
                                    <Radio.Group
                                    value={state?.selected_park_id}
                                    onChange={(e) => onParkChange(e.target.value)}
                                    >
                                        <List
                                        style={{ width:280, }}
                                        dataSource={state?.parks}
                                        renderItem={(item, index) => {
                                            const { park_id, name, } = item ?? {};
                                            return (
                                                <List.Item style={{width:'100%'}} key={index}>
                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                        <div style={{marginLeft:'auto'}}>
                                                            <Radio
                                                            value={park_id}
                                                            >
                                                            </Radio>
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                        />
                                    </Radio.Group>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Park{state?.selected_park_id && ` : ${state?.parks?.find(x => x.park_id === state?.selected_park_id)?.name}` }</span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                    </Space>
                                </div>
                            </FilterBullet>
                        </Space>
                    </div>

                    <div style={{ marginLeft : 'auto', }}>
                        <Button onClick={downloadTemplate} icon={<DownloadOutlined style={{ color : 'var(--secondary-text-color)', }} />}>Download Template</Button>
                    </div>
                </div>
            </div>

            {
                state?.selected_park_id ?
                (
                    <>
                        <div style={{ marginTop : 24, }}>
                            <ImportPromoForm form={form} park_id={state?.selected_park_id} />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div style={{ minHeight : '50vh', display : 'flex', alignItems : 'center', justifyContent : 'center', }}>
                            <span>
                                Please select park
                            </span>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default ImportPromoModal;