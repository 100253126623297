import { Col, Progress, Row } from 'antd';
import React, { useEffect } from 'react';
import PromoUsage from '../dashboards/promo_usage';
import PromoAffiliate from '../dashboards/promo_affiliate';
import DefaultPromotionCodeLink from '../dashboards/default_promotion_code_link';

const PromoChart = ({ promotion_code }) => {

    return (
        <>
            <Row gutter={[12, 12]}>
                {
                    promotion_code?.limit_number != null && (
                        <>
                            <Col xs={24} sm={12} md={24} lg={12} span={8}>
                                <PromoUsage promotion_code={promotion_code} />
                            </Col>
                        </>
                    )
                }
                <Col xs={24} sm={12} md={24} lg={12} span={8}>
                    <PromoAffiliate promotion_code={promotion_code} />
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} span={8}>
                    <DefaultPromotionCodeLink promotion_code={promotion_code} />
                </Col>
            </Row>
            
        </>
    );
}

export default PromoChart;