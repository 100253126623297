import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getRoleDetail(query){
    const queryString = serviceHelper.queryString(query);
    const roleDetailResponse = await axios.get(`admin/role/detail/get?${queryString}`, { withCredentials : true });
    return roleDetailResponse?.data;
}

async function getFeatureList(query){
    const queryString = serviceHelper?.queryString(query);
    const featureListResponse = await axios?.get(`admin/feature/get?${queryString}`, { withCredentials : true, });
    return featureListResponse?.data;
}

export default {
    getRoleDetail,
    getFeatureList,
}