import { Button, Col, Divider, Row, Space, Statistic, Tag } from 'antd';
import React, { useRef } from 'react';
import GrowthChart from './growth_chart';
import BreakdownTicketType from './breakdown_ticket_type';
import BreakdownPurchaseType from './breakdown_purchase_type';
import BreakdownAddOnType from './breakdown_add_on_type';
import Visitors from '../visitors';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import numberHelper from '../../../../helpers/functions/number.helper';
import CountUp from 'react-countup';

const ChartView = ({ filter, total_date_breakdown_sales, total_sales, ticket_types, purchase_types, add_on_types, park, }) => {
    const componentRef = useRef();
    const formatter = (value) => <CountUp end={value} separator="," decimals={2} style={{ fontSize : 20, }} />;
    return (
        <>
            <div style={{display:'flex'}}>
                <div style={{marginLeft:'auto'}}>
                    <ReactToPrint 
                    trigger={() => (
                        <Button icon={<PrinterOutlined />}>Print</Button>
                    )}
                    content={() => componentRef.current}
                    pageStyle={`@page {
                        margin: 24px;
                    }`}
                    />
                </div>
            </div>
            
            <div ref={componentRef}>
                <div>
                    <div style={{textAlign:'left'}}>
                        <span style={{fontSize:24, fontWeight:'bold', color:'var(--secondary-text-color)'}}>Overview</span>
                    </div>
                    <Divider style={{marginTop:8,}} />
                    <Row gutter={[60, 20]}>
                        <Col xs={24} md={16} span={16}>
                            <GrowthChart breakdown_sales={total_date_breakdown_sales} park={park} />
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <div style={{textAlign:'left', }}>
                                    <Row gutter={[20, 20]}>
                                        <Col span={12} xs={12} md={24} lg={12}>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Ticket Amount (Incl. Tax)</span>
                                                </div>
                                                <div style={{marginTop:6}}>
                                                    {
                                                        total_sales &&
                                                        (
                                                            <>
                                                                <Space>
                                                                    <div>
                                                                        <Tag style={{ border :'none', fontWeight:500, }} color='blue'>
                                                                            {park?.currency?.code}
                                                                        </Tag>
                                                                    </div>
                                                                    <div>
                                                                        <Statistic
                                                                        style={{ lineHeight : 1, }}
                                                                        prefix={(
                                                                            <>
                                                                                <span style={{ fontSize : 20, }}>{park?.currency?.symbol}</span>
                                                                            </>
                                                                        )}
                                                                        precision={2}
                                                                        value={total_sales?.sales_amount}
                                                                        formatter={formatter}
                                                                        />
                                                                        {/* <span style={{fontSize:20}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_sales?.sales_amount)}`}</span> */}
                                                                    </div>
                                                                </Space>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12} xs={12} md={24} lg={12}>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Add On Amount (Incl. Tax)</span>
                                                </div>
                                                <div style={{marginTop:6}}>
                                                    {
                                                        total_sales &&
                                                        (
                                                            <>
                                                                <Space>
                                                                    <div>
                                                                        <Tag style={{ border :'none', fontWeight:500, }} color='blue'>
                                                                            {park?.currency?.code}
                                                                        </Tag>
                                                                    </div>
                                                                    <div>
                                                                        <Statistic
                                                                        style={{ lineHeight : 1, }}
                                                                        prefix={(
                                                                            <>
                                                                                <span style={{ fontSize : 20, }}>{park?.currency?.symbol}</span>
                                                                            </>
                                                                        )}
                                                                        precision={2}
                                                                        value={total_sales?.add_on_amount}
                                                                        formatter={formatter}
                                                                        />
                                                                        {/* <span style={{fontSize:20}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_sales?.add_on_amount)}`}</span> */}
                                                                    </div>
                                                                </Space>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={12} xs={12} md={24} lg={12}>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Tax Amount</span>
                                                </div>
                                                <div style={{marginTop:6}}>
                                                    {
                                                        total_sales &&
                                                        (
                                                            <>
                                                                <Space>
                                                                    <div>
                                                                        <Tag style={{ border :'none', fontWeight:500, }} color='blue'>
                                                                            {park?.currency?.code}
                                                                        </Tag>
                                                                    </div>
                                                                    <div>
                                                                        <Statistic
                                                                        style={{ lineHeight : 1, }}
                                                                        prefix={(
                                                                            <>
                                                                                <span style={{ fontSize : 20, }}>{park?.currency?.symbol}</span>
                                                                            </>
                                                                        )}
                                                                        precision={2}
                                                                        value={total_sales?.tax_amount}
                                                                        formatter={formatter}
                                                                        />
                                                                        {/* <span style={{fontSize:20, color : 'var(--secondary-text-color)'}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_sales?.tax_amount)}`}</span> */}
                                                                    </div>
                                                                </Space>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:24,}} gutter={[60, 20]}>
                                        <Col span={12} xs={12} md={24} lg={12}>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Tickets Count</span>
                                                </div>
                                                <div style={{marginTop:6}}>
                                                    {
                                                        total_sales && (
                                                            <>
                                                                <span style={{fontSize:20, color: 'var(--main-color)'}}>{`${numberHelper?.commaNumber(total_sales?.ticket_user_count, 0)}`}</span>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12} xs={12} md={24} lg={12}>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Add On Count</span>
                                                </div>
                                                <div style={{marginTop:6}}>
                                                    {
                                                        total_sales && (
                                                            <>
                                                               <span style={{fontSize:20, color:'var(--main-color)'}}>{numberHelper?.commaNumber(total_sales?.add_on_count, 0) }</span>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{marginTop:48,}}>
                    <div style={{textAlign:'left'}}>
                        <span style={{fontSize:24, fontWeight:'bold', color:'var(--secondary-text-color)'}}>Breakdown</span>
                    </div>
                    <Divider style={{marginTop:8,}} />
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={12} lg={12} span={8}>
                            <div>
                                <div style={{textAlign:'start',}}>
                                    <span style={{fontSize:16, fontWeight:500, color:'var(--secondary-text-color)'}}>Ticket Types</span>
                                </div>
                                <div style={{marginTop:12,}}>
                                    <BreakdownTicketType ticket_types={ticket_types} park={park} />
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={12} lg={12} span={8}>
                            <div style={{height:'100%'}}>
                                <div style={{textAlign:'start',}}>
                                    <span style={{fontSize:16, fontWeight:500, color:'var(--secondary-text-color)'}}>Purchase Types</span>
                                </div>
                                <div style={{marginTop:12,}}>
                                    <BreakdownPurchaseType purchase_types={purchase_types} park={park} />
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={12} lg={12} span={8}>
                            <div style={{textAlign:'start',}}>
                                <span style={{fontSize:16, fontWeight:500, color:'var(--secondary-text-color)'}}>Add On Types</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <BreakdownAddOnType add_on_types={add_on_types} park={park} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default ChartView;