import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';

const { TextArea } = Input;
const DetailForm = ({ role, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        edit_mode : false,
    })

    useEffect(() => {
        form.setFieldsValue({...role,})
    }, [role])

    return (
        <>
            <Form
            form={form}
            layout='vertical'
            >
                <div style={{ textAlign : 'end', }}>
                    <Space>
                    {
                        state?.edit_mode ?
                        (
                            <>
                                <Button loading={state?.save_loading} onClick={() => form.submit()} size='small' icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />} />
                            </>
                        )
                        :
                        (
                            <>
                                <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} size='small' icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />} />
                            </>
                        )
                    }
                    </Space>
                </div>
                <Form.Item name={"name"} label={"Name"}>
                    {
                        state?.edit_mode ? (
                            <>
                                <Input />
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{textAlign:'start',}}>
                                    <span style={{color:'var(--secondary-text-color)'}}>{role?.name}</span>
                                </div>
                            </>
                        )
                    }
                </Form.Item>

                <Form.Item name={"description"} label={"Description"}>
                    {
                        state?.edit_mode ? (
                            <>
                                <TextArea rows={4} style={{ resize : 'none', }} />
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{textAlign:'start',}}>
                                    <span style={{color:'var(--secondary-text-color)'}}>{role?.description}</span>
                                </div>
                            </>
                        )
                    }
                </Form.Item>
            </Form>
        </>
    );
}

export default DetailForm;