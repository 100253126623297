import { Avatar, Button, Space } from 'antd';
import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { Link } from 'react-router-dom';

const DetailRoleAccounts = ({ accounts, }) => {
    return (
        <>
            <div style={{ textAlign : 'start', }}>
                <Space wrap>
                {
                    accounts?.map((account, index) => {
                        const { username, full_name, account_id, } = account;
                        const get_account_name = (full_name ?? username);
                        const color = globalHelper?.stringToColor(get_account_name);
                        const button_color = globalHelper?.hexToRgba(color, 0.07);
                        
                        const initials = get_account_name?.trim()?.split(/\s+/)
                        .slice(0, 2) // Get first two words
                        .map(word => word?.[0]?.toUpperCase()) // Extract and uppercase first letter
                        .join(''); // Join them together

                        return (
                            <Link key={index} to={`/account/detail/${account_id}`}>
                                <Button
                                type='text'
                                style={{ height : 'auto', background : button_color, borderRadius : 16, paddingLeft : 4, minWidth : 120, textAlign : 'start', }}
                                >
                                    <Space>
                                        <Avatar style={{ backgroundColor: color,}}>
                                            {initials}
                                        </Avatar>
                                        <div>
                                            {get_account_name}
                                        </div>
                                    </Space>
                                </Button>
                            </Link>
                        )
                    })
                }
                </Space>
            </div>
        </>
    );
}

export default DetailRoleAccounts;