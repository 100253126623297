import { Col, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import IconComponent from '../../helpers/components/icon.component';
import menuService from '../../services/admin/menu.service';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

const MasterMenu = ({search}) => {
    const [state, setState] = useState({
        loading : false,
        menu_groups : [],
    })

    const fetchMenuByAccess = async () => {
        setState(state => ({...state, loading : true}));
        const menuResponse = await menuService?.getMenus({search, app_code : 'ADM'});

        setTimeout(() => {
            setState(state => ({...state, loading : false, menu_groups : menuResponse?.menu_groups}));
        }, 200);
        
    }

    
    useEffect(() => {
        fetchMenuByAccess();
    }, [search])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Space size={16} style={{width:'100%'}} direction='vertical'>
                    {
                        state?.menu_groups?.length > 0 ?
                        (
                            <>
                                {
                                    state?.menu_groups?.map((menu_group, index) => {
                                        const { name : menu_group_name, sequence, menus } = menu_group ?? {};
                                        return (
                                            <>
                                                <div key={index}>
                                                    <div className='menu-title'>
                                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500, fontSize:16,}}>{menu_group_name}</span>
                                                    </div>
                
                                                    <div style={{marginTop:12,}}>
                                                        <Row gutter={[8, 8]}>
                                                            {
                                                                menus?.map((menu, index) => {
                                                                    const { name : menu_name, link, icon } = menu ?? {};
                                                                    return (
                                                                        <Col xs={8} sm={4} span={4}>
                                                                            <Link to={link}>
                                                                                <div style={{textAlign:'center'}}>
                                                                                    <div>
                                                                                        <IconComponent name={icon} size={30} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span style={{color:'#000'}}>{menu_name}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{textAlign:'center'}}>
                                    <span style={{color:'var(--secondary-text-color)', fontWeight:500}}>
                                        No Menu Found
                                    </span>
                                </div>
                            </>
                        )
                        
                    }
                </Space>
            </Spin>
        </>
    );
}

export default MasterMenu;