import { Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import promotionService from '../../../services/config/promotion.service';

const ImportPromoAddOn = ({ park_id, form, }) => {
    const [state, setState] = useState({
        loading : false,

        columns : [],
        datas : [],
        add_ons : [],
    })

    const [updated, setUpdated] = useState([]); // to set state on changed toggle

    const toggleChanges = (is_applicable, add_on_setup_id) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.add_on_setup_id == add_on_setup_id);
        if(exisiting_updated_date){
            // update existing
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            // push new
            updated_data?.push({ is_applicable, add_on_setup_id, });
        }

        form.setFieldsValue({ add_ons : updated_data, });
        setUpdated(updated_data);
    }

    const fetchPromoCodeAddOn = async () => {
        setState(state => ({...state, loading : true, }));
    
        let columns = [];
        let datas = [];
        let get_add_ons = [];
        const promoCodeAddOnResponse = await promotionService?.getPromoCodeApplicableAddOn(park_id);
        const { ticket_types, add_ons } = promoCodeAddOnResponse;
        get_add_ons = add_ons;
        columns = await generateColumns(add_ons);
        datas = await generateDataSource(ticket_types);

        setState(state => ({...state, loading : false, columns, datas, add_ons : get_add_ons }))
    }

    const generateColumns = async (add_ons) => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })


        // map data
        for(const add_on of add_ons){
            const { name, add_on_id, } = add_on;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, add_on_setup_id } = applicable[add_on_id] ?? {};
                    // from state
                    const checked_toggle = updated?.find(x => x.add_on_setup_id === add_on_setup_id) ?? false;

                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        <Switch
                                        onChange={(e) => toggleChanges(e, add_on_setup_id)}
                                        size='small'
                                        checked={checked_toggle?.is_applicable}
                                        // defaultChecked={checked_toggle?.is_applicable}
                                        />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateDataSource = async (ticket_types) => {
        // map data
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id } = x;
            const { add_ons } = park_applicable_ticket_types ?? {};

            let applicable = {};
            for(const add_on of add_ons){
                const { AddOnSetup : add_on_setup } = add_on;
                applicable[add_on?.add_on_id] = {
                    is_applicable : add_on?.is_applicable,
                    add_on_setup_id : add_on_setup?.add_on_setup_id,
                };
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    useEffect(() => {
        fetchPromoCodeAddOn();
    }, [park_id])
    
    return (
        <>
            <Table
            loading={state?.loading}
            scroll={{x : 600,}}
            pagination={false}
            className='hub-table bordered'
            dataSource={state?.datas}
            columns={state?.columns}
            />
        </>
    );
}

export default ImportPromoAddOn;