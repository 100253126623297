import React, { useEffect, useState } from 'react';
import adminService from '../../../services/admin/admin.service';
import ContainerComponent from '../../../helpers/components/container.component';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import globalHelper from '../../../helpers/functions/global.helper';
import DetailForm from './detail_form';
import { Divider } from 'antd';
import DetailRoleAccounts from './detail_role_accounts';
import DetailRoleFeatures from './detail_role_features';

const RoleDetailLayout = ({ role_id, }) => {
    const [state, setState] = useState({
        loading : false,
        role : null,
    })

    const fetchRole = async () => {
        setState(state => ({...state, loading : true, }));
        const roleResponse = await adminService?.getRole({
            role_id,
        });

        const { roles, } = roleResponse;
        const role = roles?.[0];
        setState(state => ({...state, role, loading : false, }));
    }

    useEffect(() => {
        fetchRole();
    }, [role_id])

    return (
        <>
            <div style={{ marginBottom : 24, }}>
                <div className='title-header mobile-padding-24' style={{textAlign:'start'}}>
                    <ContainerComponent span={20}>
                        <div style={{ display : 'flex', alignItems : 'center',}}>
                            <div>
                                <PageHeaderComponent title={'Role Detail'} />
                            </div>
                        </div>
                    </ContainerComponent>
                </div>

                <div className='mobile-padding-24' {...!globalHelper?.isMobileScreen() && ({ style : { marginTop :24, } })}>
                    <ContainerComponent span={20}>
                        <div>
                            <DetailForm role={state?.role} />
                        </div>
                    </ContainerComponent>
                    <Divider />
                    <ContainerComponent span={20}>
                        <div>
                            <DetailRoleAccounts accounts={state?.role?.accounts} />
                        </div>
                    </ContainerComponent>
                    <Divider />
                    <ContainerComponent span={20}>
                        <div>
                            <DetailRoleFeatures role={state?.role} />
                        </div>
                    </ContainerComponent>
                </div>
            </div>
        </>
    );
}

export default RoleDetailLayout;