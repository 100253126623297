import { Badge, Button, Card, Form, Image, Input, Popconfirm, Tag, Upload } from 'antd';
import React, { useMemo, useState } from 'react';
import StepNoItem from '../../../helpers/components/step_no_item';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill, { Qui } from 'react-quill-v2.0';
import 'react-quill-v2.0/dist/quill.snow.css';

const { TextArea } = Input;
const ParkOverview = ({ form, beforeUpload = () => {}, removeImage = () => {}, image, park, }) => {
    const [state, setState] = useState({
        remove_loading : false,
    })

    const onDeltaChange = (text, delta, source, editor) => {
        const delta_content = (editor.getContents())
        form.setFieldsValue({ description : JSON.stringify(delta_content),});
    }

    const onBannerChange = (text, delta, source, editor) => {
        const delta_content = (editor.getContents())
        let content = JSON.stringify(delta_content);

        const isEmpty = (!text || text === "<p><br></p>");
        if(isEmpty){
            content = null;
        }

        form.setFieldsValue({ banner_text : content,});
        
    }

    const description_delta = useMemo(() => {
        return park?.description ? JSON.parse(park?.description) : null;
    }, [park])

    const banner_delta = useMemo(() => {
        return park?.banner_text ? JSON.parse(park?.banner_text) : null;
    }, [park])

    return (
        <>
            <Card
            // headStyle={{ padding : 12, }}
            styles={{ header : { padding : 12, } }}
            title={(
                <>
                    <div style={{textAlign : 'start',}}>
                        <StepNoItem no={2} text={'Park Overview'} />
                    </div>
                </>
            )}
            >
                <div>
                    <div style={{ textAlign : 'start', }}>
                        <Form.Item label="Logo" style={{ marginBottom : 0, }}>
                            <Badge
                            offset={[-4, 0]}
                            count={(
                                <Button onClick={() => removeImage('image')} loading={state?.remove_loading} size='small' icon={<CloseOutlined />}></Button>
                            )}
                            >
                                <Form.Item name={'logo_image'}>
                                    <Upload
                                    name="logo_image"
                                    listType='picture-card'
                                    beforeUpload={(file) => beforeUpload('logo_image', file)}
                                    showUploadList={false}
                                    style={{width : '100%',}}
                                    >
                                        {
                                            image ?
                                            (
                                                <>
                                                    <Image preview={false} src={image} />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <div>
                                                            <PlusOutlined />
                                                        </div>
                                                        <div>Upload</div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </Upload>
                                </Form.Item>
                            </Badge>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label='Park Code' style={{textAlign : 'start',}}>
                            <Tag color='blue' style={{ border : 'none', }}>{park?.park_code}</Tag>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label='Park Name' name={'name'}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label='Address' name={'address'}>
                            <TextArea
                            rows={4}
                            style={{ resize : 'none', }}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Description" name="description">
                            <div>
                                <ReactQuill
                                // readOnly
                                // theme={null}
                                theme='snow'
                                modules={{
                                    toolbar : true,
                                }}
                                placeholder='Enter descriptions'
                                onChange={onDeltaChange}
                                value={description_delta}
                                />
                            </div>
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item label="Banner" name="banner_text">
                            <div>
                                <ReactQuill
                                // readOnly
                                // theme={null}
                                theme='snow'
                                modules={{
                                    toolbar : true,
                                }}
                                placeholder='Enter banner'
                                onChange={onBannerChange}
                                value={banner_delta}
                                />
                            </div>
                        </Form.Item>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ParkOverview;