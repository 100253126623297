import React from 'react';
import RoleDetailLayout from './components/detail_layout';
import { useParams } from 'react-router-dom';

const RoleDetail = () => {
    const { role_id, } = useParams();
    return (
        <>
            <RoleDetailLayout role_id={role_id} />
        </>
    );
}

export default RoleDetail;