import React, { useEffect, useState } from 'react';
import promotionService from '../../../services/config/promotion.service';
import { Col, Divider, Row, Skeleton, Spin } from 'antd';
import PromoDetailConfig from './promo_detail_config';
import globalHelper from '../../../helpers/functions/global.helper';
import PromoDetailGeneral from './promo_detail_general';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import PromoDetailHeader from './promo_detail_header';

const PromoDetailLayout = ({ promotion_code_id }) => {
    const configRedux = useSelector(state => state?.config);
    const { innerHeight } = window;
    const [state, setState] = useState({
        loading : true,
        promotion_code : null,
    })

    const fetchPromotionCode = async () => {
        setState(state => ({...state, loading : true,}));
        const promotionCodeResponse = await promotionService?.getPromoCode({
            promotion_code_id,
        });

        const { count, rows : promotion_codes } = promotionCodeResponse?.promotion_codes;
        const promotion_code = promotion_codes?.length > 0 ? promotion_codes[0] : null;

        setTimeout(() => {
            setState(state => ({...state, loading : false, promotion_code : promotion_code, }));
        }, 500);
        
    }

    useEffect(() => {
        fetchPromotionCode();
    }, [configRedux?.promo_code_detail_refresh])

    return (
        <>
            <div className='mobile-padding-12'>
                {
                    globalHelper?.isMobileScreen() ?
                    (
                        <>
                            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                <div>
                                    <PromoDetailHeader promotion_code={state?.promotion_code} />
                                </div>
                                <Divider />
                                <div>
                                    <div>
                                        <PromoDetailGeneral promotion_code={state?.promotion_code} />
                                    </div>
                                    <div>
                                        <div style={{margin : '20px 0',}}>
                                            <PromoDetailConfig promotion_code={state?.promotion_code} />
                                        </div>
                                    </div>
                                </div>
                            
                            </Spin>
                        </>
                    )
                    :
                    (
                        <>
                            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                <Row gutter={[20, 20]} justify={'center'}>
                                    <Col xs={0} md={16} span={16} style={{height : '100%'}}>
                                        <div style={{borderRight:'1px solid #d9d9d9', width:'100%'}}>
                                            <div style={{ padding : '20px 0', paddingRight:20,}}>
                                                <PromoDetailHeader promotion_code={state?.promotion_code}  />
                                                <PromoDetailConfig promotion_code={state?.promotion_code} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8} span={8}>
                                        <div style={{margin : '20px 0',}}>
                                            <PromoDetailGeneral promotion_code={state?.promotion_code} />
                                        </div>
                                    </Col>
                                </Row>
                            </Spin>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default PromoDetailLayout;