import { UploadOutlined } from '@ant-design/icons';
import { Form, Space, Upload } from 'antd';
import React, { useState } from 'react';
import ImportPromoTicketTypeAvailability from './import_promo_ticket_type_availability';
import ImportPromoAddOn from './import_promo_add_on';
import ImportPromoDate from './import_promo_date';

const ImportPromoForm = ({ form, park_id, }) => {
    const [state, setState] = useState({
        uploaded_file : null,
    })

    const acceptable_file_format = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
    const beforeUpload = async (file) => {
        // check whether is excel or not
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });

            setState(state => ({...state, uploaded_file : uploaded_file, }));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    return (
        <>
            <div>
                <Form.Item name={'file'}>
                    <Upload
                    name='promo_list'
                    listType='picture-card'
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    className="promo-xlsx-upload-container"
                    style={{ width : '100%', }}
                    >
                        <div>
                            {
                                state?.uploaded_file ?
                                (
                                    <>
                                        <div>
                                            {/* <FileOutlined style={{ color : 'var(--secondary-text-color)', }} /> */}
                                        </div>
                                        <div>
                                            <span>{state?.uploaded_file?.name}</span>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <UploadOutlined />
                                        </div>
                                        <div>
                                            <span>Upload</span>
                                        </div>
                                    </>
                                )
                            }
                            
                        </div>
                    </Upload>
                </Form.Item>
            </div>

            {/* This form need to be separated as it will be dynamics based on the ticket type of the park */}
            <div>
                <Form.Item name={"ticket_applicable_users"} hidden />
                <ImportPromoTicketTypeAvailability park_id={park_id} form={form} />
            </div>
            
            <div style={{ marginTop : 24, }}>
                <Form.Item name={"add_ons"} hidden />
                <ImportPromoAddOn park_id={park_id} form={form} />
            </div>

            <div style={{ marginTop : 24, }}>
                <Form.Item name={"visit_dates"} hidden />
                <ImportPromoDate form={form} />
            </div>
        </>
    );
}

export default ImportPromoForm;