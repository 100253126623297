import React from 'react';
import RoleLayout from './components/role_layout';
import ContainerComponent from '../../helpers/components/container.component';

const RoleIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <RoleLayout />
            </ContainerComponent>
        </>
    );
}

export default RoleIndex;