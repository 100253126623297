import React, { useEffect, useState } from 'react';
import salesService from '../../../services/ticket/sales.service';
import { Col, Divider, Empty, Progress, Row, Space, Spin, Statistic } from 'antd';
import ChartView from './chart/chart_view';
import GridView from './grid/grid_view';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import moment from 'moment';

const ReportItem = ({ filter, view_type, park, setErrors = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        total_sales : null,
        purchase_types : [],
        ticket_types : [],
        add_on_types : [],
        total_date_breakdown_sales : [],
        errors : [],
    })

    const fetchSalesReport = async () => {
        setState(state => ({...state, loading : 1}));
        const { park, start_date, end_date, } = filter;

        // calculation
        let total_sales = {};
        let purchase_types = new Map();
        let ticket_types = new Map();
        let add_on_types = new Map();
        let total_date_breakdown_sales = [];
        let errors = [];

        const date_ranges = splitDateRange(start_date, end_date);
        let completed = 0;
        for(const date_range of date_ranges){
            const { start, end, } = date_range;
            const reportResponse = await salesService.generateSalesReport({
                park_id : park,
                start_date : start,
                end_date : end,
            });

            const {
                total_sales : report_total_sales,
                purchase_types : report_puchase_types,
                ticket_types : report_ticket_types,
                total_date_breakdown_sales : report_total_date_breakdown_sales,
                add_on_types : report_add_on_types,
                errors : report_errors,
            } = reportResponse;

            // Accumulate values in total_sales
            for (const key in report_total_sales) {
                if (report_total_sales.hasOwnProperty(key)) {
                    total_sales[key] = (total_sales[key] || 0) + report_total_sales[key];
                }
            }

            // accumulate purchase types
            for(const purchase_type of report_puchase_types){
                const { purchase_type_id, transaction_count, ticket_user_count, sales_amount } = purchase_type;
                if(!purchase_types?.has(purchase_type_id)){
                    purchase_types?.set(purchase_type_id, {
                        ...purchase_type,
                        sales_amount : Number(sales_amount),
                    })
                }else{
                    // Accumulate values
                    const exisiting = purchase_types?.get(purchase_type_id);
                    exisiting.transaction_count += transaction_count;
                    exisiting.ticket_user_count += ticket_user_count;
                    exisiting.sales_amount += Number(sales_amount);
                }
            }

            // accumulate ticket types
            for(const ticket_type of report_ticket_types){
                const { ticket_type_id, transaction_count, ticket_user_count, sales_amount } = ticket_type;

                if(!ticket_types?.has(ticket_type_id)){
                    // Store a deep copy to avoid mutation
                    ticket_types?.set(ticket_type_id, {
                        ...ticket_type,
                        sales_amount : Number(sales_amount),
                    })
                }else{
                    // Accumulate values
                    const existing = ticket_types.get(ticket_type_id);
                    existing.transaction_count += transaction_count;
                    existing.ticket_user_count += ticket_user_count;
                    existing.sales_amount += Number(sales_amount);
                }
            }

            // accumulate add ons
            for(const add_on_type of report_add_on_types){
                const { add_on_type_id, count, sales_amount, } = add_on_type;
                if(!add_on_types?.has(add_on_type_id)){
                    add_on_types?.set(add_on_type_id, {
                        ...add_on_type,
                        sales_amount : Number(sales_amount),
                    })
                }else{
                    // Accumulate values
                    const existing = add_on_types.get(add_on_type_id);
                    existing.count += count;
                    existing.sales_amount += sales_amount;
                }
            }

            // Push date breakdown sales
            total_date_breakdown_sales.push(...report_total_date_breakdown_sales);
            
            // Collect errors
            errors.push(...report_errors);

            // set percentage
            completed++;
            const percent = ((completed / date_ranges?.length) * 100);
            setState(state => ({...state, loading : percent, }))
        }

        setState(state => ({...state, loading : 100, }))

        setTimeout(() => {
            setState(state => ({...state,
                loading : false,
                total_sales,
                purchase_types : (Array.from(purchase_types?.values())),
                ticket_types : (Array.from(ticket_types?.values())),
                add_on_types : (Array?.from(add_on_types?.values())),
                total_date_breakdown_sales,
            }))
            
            setErrors(errors)
        }, 300);
        // const { total_sales, purchase_types, ticket_types, total_date_breakdown_sales, add_on_types, errors, } = reportResponse ?? {};
        // setState(state => ({...state, total_sales, purchase_types, ticket_types, total_date_breakdown_sales, add_on_types, errors, loading : false, }));
    }
    
    function splitDateRange(start_date, end_date) {
        let start = moment(start_date, "YYYY-MM-DD HH:mm:ss");
        let end = moment(end_date, "YYYY-MM-DD HH:mm:ss");
    
        let date_ranges = [];
    
        // First entry: from given start time to end of that day
        date_ranges.push({
            start: start.format("YYYY-MM-DD HH:mm:ss"),
            end: start.clone().endOf('day').format("YYYY-MM-DD HH:mm:ss")
        });
    
        // Middle entries: full days from 00:00:00 to 23:59:59
        let current = start.clone().add(1, 'day').startOf('day');
        while (current.isBefore(end, 'day')) {
            date_ranges.push({
                start: current.format("YYYY-MM-DD HH:mm:ss"),
                end: current.clone().endOf('day').format("YYYY-MM-DD HH:mm:ss")
            });
            current.add(1, 'day');
        }
    
        // if start date and end date is same, just ignore the end
        if(!start?.isSame(end, 'day')){
            // Last entry: from 00:00:00 to the given end time
            date_ranges.push({
                start: end.clone().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
                end: end.format("YYYY-MM-DD HH:mm:ss")
            });
        }

        return date_ranges;
    }

    useEffect(() => {
        if(filter?.park){
            fetchSalesReport();
        }
        
    }, [filter])

    return (
        <>
            <Spin tip={`${Math.round((state?.loading ?? 0))}%`} size='large' fullscreen percent={state?.loading} spinning={state?.loading} />
            {
                state?.errors?.length > 0 ?
                (
                    <>
                        <div style={{ padding : 42, }}>
                            <EntranceMotion>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={state?.errors?.[0]?.message} />
                            </EntranceMotion>
                        </div>
                    </>
                )
                :
                (
                    <>
                        {
                            view_type === 'chart' &&
                            (
                                <>
                                    <ChartView
                                    filter={filter}
                                    total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                    total_sales={state?.total_sales}
                                    ticket_types={state?.ticket_types}
                                    purchase_types={state?.purchase_types}
                                    add_on_types={state?.add_on_types}
                                    park={park}
                                    />
                                </>
                            )
                        }
                        {
                            view_type === 'grid' &&
                            (
                                <>
                                    <GridView
                                    filter={filter}
                                    total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                    total_sales={state?.total_sales}
                                    ticket_types={state?.ticket_types}
                                    purchase_types={state?.purchase_types}
                                    add_on_types={state?.add_on_types}
                                    park={park}
                                    />
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default ReportItem;