import React, { useEffect, useState } from 'react';
import adminService from '../../../services/admin/admin.service';
import { Avatar, Button, Space, Table, Tag, Tooltip } from 'antd';
import globalHelper from '../../../helpers/functions/global.helper';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const RoleList = ({ filter, }) => {
    const [state, setState] = useState({
        loading : false,

        roles : [],
    })

    const fetchRoles = async () => {
        setState(state => ({...state, loading : true, }));

        const { search, } = filter;
        const rolesReponse = await adminService?.getRole({
            search,
        });

        const { roles, } = rolesReponse;

        setState(state => ({...state, roles, loading : false, }));
    }
    
    useEffect(() => {
        fetchRoles();
    }, [filter])

    return (
        <>
            <Table
            loading={state?.loading}
            dataSource={state?.roles}
            scroll={{x : 400,}}
            className='hub-table bordered'
            columns={[{
                title : 'Role',
                dataIndex : 'name',
                key : 'name',
                width : '29%',
            },{
                title : 'Accounts',
                dataIndex : 'accounts',
                key : 'accounts',
                width : '30%',
                render : (accounts, row, index) => {
                    return (
                        <>
                            <Avatar.Group maxCount={5} maxStyle={{ color : '#f56a00', backgroundColor : '#fde3cf', }}>
                                {
                                    accounts?.map((account, index) => {
                                        const { username, full_name, } = account;
                                        const get_account_name = (full_name ?? username);
                                        const color = globalHelper?.stringToColor(get_account_name);
                                        
                                        const initials = get_account_name?.trim()?.split(/\s+/)
                                        .slice(0, 2) // Get first two words
                                        .map(word => word?.[0]?.toUpperCase()) // Extract and uppercase first letter
                                        .join(''); // Join them together

                                        return (
                                            <Tooltip key={index} title={get_account_name}>
                                                <Avatar
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                                >
                                                    {initials}
                                                </Avatar>
                                            </Tooltip>
                                        )
                                        })
                                }
                            </Avatar.Group>
                        </>
                    )
                }
            },{
                title : 'Features',
                dataIndex : 'features',
                key : 'features',
                width : '40%',
                render : (features, row, index) => {
                    return (
                        <>
                            <Space key={index} size={4} wrap>
                                {
                                    features?.slice(0, 3).map((feature, index) => {
                                        const { name, } = feature;
                                        return (
                                            <>
                                                <Tag key={index} style={{ border : 'none', background : 'var(--secondary-button-color)', }}>{name}</Tag>
                                            </>
                                        )
                                    })
                                }
                                {
                                    features?.length > 3 && (
                                        <>
                                            <Tooltip title={`${features?.length - 3} more features`}>
                                                <Tag style={{ border : 'none', background : 'var(--secondary-button-color)', }} icon={<EllipsisOutlined />} />
                                            </Tooltip>
                                        </>
                                    )
                                }
                            </Space>
                        </>
                    )
                }
            },{
                title : 'Action',
                dataIndex : 'role_id',
                key : 'role_id',
                width : '10%',
                // fixed : 'right',
                render : (role_id, row, index) => {
                    return (
                        <>
                            <Button.Group>
                                <Link to={`/role/detail/${role_id}`}>
                                    <Button style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}}>View</Button>
                                </Link>
                                {/* <Popconfirm
                                onConfirm={() => deleteStaff(staff_id, name)}
                                title={`Are you confirm to delete ${name}`}
                                okButtonProps={{ loading : state?.delete_loading, }}
                                >
                                    <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                </Popconfirm> */}
                                <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                            </Button.Group>
                        </>
                    )
                }
            }]}
            />
        </>
    );
}

export default RoleList;