import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './public.router';
import PrivateRoute from './private.router';

// Page
import OperationReport from '../containers/operation/report';
import SaleReport from '../containers/sales/report';
import TicketIndex from '../containers/ticket';
import GenerateTicket from '../containers/ticket/generate';
import TicketDetail from '../containers/ticket/detail';
import SpecialLinkIndex from '../containers/ticket/special_link';
import EmployeeIndex from '../containers/employee';
import EmployeeDetailIndex from '../containers/employee/detail';
import FaceIndex from '../containers/face';
import FaceDetail from '../containers/face/detail';
import HomeIndex from '../containers/home';
import VisitorIndex from '../containers/operation/visitor';
import PromoIndex from '../containers/promo';
import PromoDetail from '../containers/promo/detail';
import PromoDashboardIndex from '../containers/promo/dashboard';
import AffiliateIndex from '../containers/affiliate';
import StaffIndex from '../containers/staff';
import StaffDetail from '../containers/staff/detail';
import TicketFieldEdit from '../containers/ticket/field_edit';
import CustomPassIndex from '../containers/custom_pass';
import CustomPassDetail from '../containers/custom_pass/detail';
import ProfileIndex from '../containers/profile';
import ParkConfigIndex from '../containers/park';
import InvoiceIndex from '../containers/invoice';
import ConfigAddOnIndex from '../containers/config/add_on';
import ConfigTicketTypeIndex from '../containers/config/ticket_type';
import ConfigTicketUserType from '../containers/config/ticket_user_type';
import RoleIndex from '../containers/role';
import RoleDetail from '../containers/role/detail';

const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Home */}
                <PrivateRoute component={HomeIndex} path="/" exact restricted={false} />
                
                {/* Profile */}
                <PrivateRoute component={ProfileIndex} path="/account/profile" exact restricted={false} />

                {/* Operation */}
                <PrivateRoute component={OperationReport} path="/operation/report" exact restricted={false} />

                {/* Sales */}
                <PrivateRoute component={SaleReport} path="/sale/report" exact restricted={false} />

                {/* Tickets */}
                <PrivateRoute component={TicketIndex} path="/ticket" exact restricted={false} />
                <PrivateRoute component={GenerateTicket} path="/:purchase_code/generate" exact restricted={false} />
                <PrivateRoute component={TicketDetail} path="/ticket/detail/:ticket_id" exact restricted={false} />
                <PrivateRoute component={SpecialLinkIndex} path="/ticket/special_link" exact restricted={false} />
                <PrivateRoute component={TicketFieldEdit} path="/ticket/user/field/:ticket_user_id" exact restricted={false} />

                {/* Invoices */}
                <PrivateRoute component={InvoiceIndex} path="/invoice" exact restricted={false} />

                {/* Promotions */}
                <PrivateRoute component={PromoIndex} path="/promo" exact restricted={false} />
                <PrivateRoute component={PromoDetail} path="/promo/detail/:promotion_code_id" exact restricted={false} />
                <PrivateRoute component={PromoDashboardIndex} path="/promo/dashboard/:promotion_code_id" exact restricted={false} />

                {/* Employee -> Account */}
                <PrivateRoute component={EmployeeIndex} path="/account" exact restricted={false} />
                <PrivateRoute component={EmployeeDetailIndex} path="/account/:detail/:account_id" exact restricted={false} />

                {/* Affiliate */}
                <PrivateRoute component={AffiliateIndex} path="/affiliate" exact restricted={false} />

                {/* Visitors */}
                <PrivateRoute component={VisitorIndex} path="/visitor" exact restricted={false} />

                {/* Staff */}
                <PrivateRoute component={StaffIndex} path="/staff" exact restricted={false} />
                <PrivateRoute component={StaffDetail} path="/staff/detail/:staff_id" exact restricted={false} />

                {/* Custom Pass */}
                <PrivateRoute component={CustomPassIndex} path="/custom/pass" exact restricted={false} />
                <PrivateRoute component={CustomPassDetail} path="/custom/pass/detail/:custom_pass_id" exact restricted={false} />

                {/* Park Configuration */}
                <PrivateRoute component={ParkConfigIndex} path="/park/config" exact restricted={false} />

                {/* Configuration */}
                <PrivateRoute component={ConfigAddOnIndex} path="/config/add_on" exact restricted={false} />
                <PrivateRoute component={ConfigTicketTypeIndex} path="/config/ticket_type" exact restricted={false} />
                <PrivateRoute component={ConfigTicketUserType} path="/config/ticket_user_type" exact restricted={false} />

                {/* Roles */}
                <PrivateRoute component={RoleIndex} path='/role' exact restricted={false} />
                <PrivateRoute component={RoleDetail} path="/role/detail/:role_id" exact restricted={false} />

                {/* AWS Face Collections */}
                <PrivateRoute component={FaceIndex} path="/dev/face_collection" exact restricted={false} />
                <PrivateRoute component={FaceDetail} path="/dev/face_collection/:collection_id" exact restricted={false} />
            </Switch>
        </>
    );
}

export default AppRouter;