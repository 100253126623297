import { CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Calendar, Select, Space, Switch, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import promotionService from '../../../services/config/promotion.service';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const PromoDateAvailability = ({ promotion_code }) => {
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        edit_mode : false,

        promo_code_dates : [],
        promotion : null,

        selected_month : dayjs()?.month(),
        selected_year : dayjs()?.year(),
    })

    const [updated, setUpdated] = useState([]); // to set state on changed toggle

    const fetchPromoDateAvailability = async () => {
        if(promotion_code){
            setState(state => ({...state, loading : true,}));
            const { promotion_code_id } = promotion_code;
            const getPromotionCodeDateResponse = await promotionService?.getPromoCodeDate(promotion_code_id);

            const { promotion_code : get_promotion_code } = getPromotionCodeDateResponse;
            setState(state => ({...state, loading : false, promo_code_dates : get_promotion_code?.promotion_code_dates, get_promotion_code : promotion_code, }));
        }
    }

    const toggleChanges = (is_applicable, visit_date) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.visit_date == visit_date);
        if(exisiting_updated_date){
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            updated_data?.push({ is_applicable, visit_date, });
        }
        
        setUpdated(updated_data);
    }

    const savePromotionCodeDate = async () => {
        setState(state => ({...state, save_loading : true, }));

        const { promotion_code_id } = promotion_code;
        const savePromotionCodeDateResponse = await promotionService?.savePromoCodeDate(promotion_code_id, {
            promo_code_visit_dates : updated,
        });

        setTimeout(() => {
            const { saved_items } = savePromotionCodeDateResponse;
            //notify
            notification.success({
                message : "Promotion Code Updated",
                description : `Updated ${saved_items?.length} Visit Date`
            })

            setState(state => ({...state, edit_mode : false, save_loading : false, }));
            setUpdated([]); //reset updated items
        }, 500);
    }

    useEffect(() => {
        fetchPromoDateAvailability();
    }, [promotion_code, state?.edit_mode])
    
    return (
        <>
            <div>
                <div style={{textAlign:'start'}}>
                    <div style={{display:'flex',}}>
                        <div>
                            <span style={{fontWeight:500,}}>Visit Date</span>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            {
                                state?.edit_mode ?
                                (
                                    <>
                                        <Space>
                                            <Button
                                            loading={state?.save_loading}
                                            onClick={savePromotionCodeDate}
                                            size='small'
                                            icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                            size='small'
                                            icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                            size='small'
                                            icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div style={{marginTop:12,}}>
                    <Calendar
                    style={{border : '1px solid #f0f0f0', padding:12, borderRadius:8,}}
                    mode='month'
                    // onSelect={(e) => e.stopPropagation()}
                    onPanelChange={(value, mode) => {
                        const month = dayjs(value)?.month();
                        const year = dayjs(value)?.year();

                        setState(state => ({...state, selected_month : month, selected_year : year,}));
                    }}
                    cellRender={(current) => {
                        const is_specific_visit_date = promotion_code?.is_specific_visit_date;
                        const date = dayjs(current)?.format("YYYY-MM-DD");
                        const promo_code_date = state?.promo_code_dates?.find(x => x?.visit_date == date);
                        const day = dayjs(current)?.get('D');

                        // check is current month
                        // Get the current year and month
                        const current_year = state?.selected_year;
                        const current_month = state?.selected_month + 1; // Month is 0-indexed, so add 1

                        // Get the year and month components of the date to check
                        const date_to_check = dayjs(current);
                        const year_to_check = date_to_check.year();
                        const month_to_check = date_to_check.month() + 1; // Month is 0-indexed, so add 1

                        // Check if the date is in the current month
                        const is_current_month = (current_year === year_to_check) && (current_month === month_to_check);
                        let is_checked = false;
                        if(is_specific_visit_date){
                            is_checked = promo_code_date?.is_applicable;
                        }else{
                            is_checked = promo_code_date ? (promo_code_date?.is_applicable) : true;
                        }

                        return (
                            <>
                                {
                                    is_current_month &&
                                    (
                                        <>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                                <div>
                                                    <EntranceMotion>
                                                        <div>
                                                            {
                                                                state?.edit_mode ?
                                                                (
                                                                    <>
                                                                        <Switch
                                                                        onChange={(e) => toggleChanges(e, date)}
                                                                        size='small'
                                                                        defaultChecked={is_checked}
                                                                        />
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        {
                                                                            is_checked ? 
                                                                            (
                                                                                <>
                                                                                    <CheckCircleFilled style={{color:'#95de64'}} />
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <>
                                                                                    <CloseCircleFilled style={{color:'#d9d9d9',}} />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </EntranceMotion>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }}
                    />
                </div>
            </div>
        </>
    );
}

export default PromoDateAvailability;