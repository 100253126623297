import { Checkbox, Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import TaxApplicableTicketTable from './tax_applicable_ticket_table';
import parkService from '../../../services/config/park.service';
import TaxApplicableTicketTableConfiguration from './tax_applicable_ticket_table_configuration';
import TaxApplicableAddOnTableConfiguration from './tax_applicable_add_on_table_configuration';
import { useDispatch } from 'react-redux';
import { set_tax_ticket_user_check_all } from '../../../actions/config.action';

const AddParkTaxModal = ({ form, ticket_user_types, park, }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        ticket_types : [],
        is_calculated_by_total_switch : false,
    });

    return (
        <>
            <Form.Item name={'name'} label={'Name'}>
                <Input />
            </Form.Item>

            <Row gutter={[12, 12]}>
                <Col xs={24} sm={12}>
                    <Form.Item name={'percentage'} label={'Percentage'}>
                        <InputNumber min={0} style={{ width : '100%', }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item name="value" label="Value">
                        <Input placeholder='0.00' allowClear type='number' style={{width:'100%'}} />
                    </Form.Item>
                </Col>
            </Row>


            <Form.Item name={"is_calculated_by_total"} label={"Is Calculate By Total Amount"}>
                <Switch
                onChange={(e) => setState(state => ({...state, is_calculated_by_total_switch : e, }))}
                />
            </Form.Item>

            {
                !state?.is_calculated_by_total_switch && (
                    <>
                        {/* Table for selecting ticket applicable users */}
                        <div>
                            <Form.Item
                            className='form-width-label'
                            name={'park_tax_ticket_applicable_users'}
                            >
                                <TaxApplicableTicketTableConfiguration form={form} ticket_user_types={ticket_user_types} ticket_types={park?.ticket_types} />
                            </Form.Item>
                        </div>

                        {/* Table for add ons */}
                        <div>
                            <Form.Item name={'park_tax_add_ons'}>
                                <TaxApplicableAddOnTableConfiguration park_id={park?.park_id} form={form} ticket_types={park?.ticket_types} />
                            </Form.Item>
                        </div>
                    </>
                )
            }
            
        </>
    );
}

export default AddParkTaxModal;