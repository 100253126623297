import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Button, Card, Collapse, Empty, Form, Modal, Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import { CaretRightFilled, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import TaxApplicableTicketTable from './tax_applicable_ticket_table';
import TaxApplicableAddOnTable from './tax_applicable_add_on_table';
import AddParkTaxModal from './add_park_tax_modal';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import EditParkTaxModal from './edit_park_tax_modal';

const TaxLayout = ({ park_id, }) => {
    const [save_form] = Form.useForm();
    const [update_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        park : null,
        park_taxes : [],

        ticket_user_types : [],
        add_ons : [],

        // crud item
        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        refresh : 0,
        modal : 0,
    })

    const openEditModal = async (park_tax_id) => {
        setState(state => ({...state, edit_modal : park_tax_id, }))
    }

    const saveParkTax = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, percentage, value, park_tax_ticket_applicable_users, park_tax_add_ons, is_calculated_by_total, } = save_form?.getFieldsValue();

        const saveParkTaxResponse = await parkService?.saveParkTax({
            park_id, name, percentage,value, park_tax_ticket_applicable_users, park_tax_add_ons, is_calculated_by_total,
        })?.then(result => {
            notification.success({
                message : 'Tax Added',
                description : `${name}`,
            })
    
            setState(state => ({...state, save_loading : false, create_modal : false, refresh : (state?.refresh + 1), modal : (state?.modal + 1), }));
            save_form?.resetFields();
        }).catch(err => {
            setState(state => ({...state, save_loading : false,}))
        });
    }

    const updateParkTax = async () => {
        setState(state => ({...state, update_loading : true, }));
        const park_tax_id = state?.edit_modal;
        const { name, percentage, value, park_tax_ticket_applicable_users, park_tax_add_ons, is_calculated_by_total, } = update_form?.getFieldsValue();
        
        const updateParkTaxResponse = await parkService?.updateParkTax(park_tax_id, {
            name, percentage, value, park_tax_ticket_applicable_users, park_tax_add_ons, is_calculated_by_total,
        }).then((result) => {
            notification.success({
                message : 'Tax Updated',
                description : `${name}`,
            })
    
            setState(state => ({...state, update_loading : false, edit_modal : false, refresh : (state?.refresh + 1), modal : (state?.modal + 1), }));
        }).catch(err => {
            setState(state => ({...state, update_loading : false, }));
        })
    }

    const deleteParkTax = async (park_tax_id) => {
        const deleteParkTaxResponse = await parkService?.deleteParkTax(park_tax_id);
        
        notification?.success({
            message : 'Tax Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchParkTaxes = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id, });
        const parkTaxResponse = await parkService?.getParkTax({
            park_id : park_id,
        });

        const { park_taxes, ticket_user_types, add_ons, } = parkTaxResponse;
        const { parks } = parkResponse;

        setState(state => ({...state, loading : false, park_taxes : park_taxes, ticket_user_types : ticket_user_types, add_ons : add_ons, park : parks, }));
    }

    useEffect(() => {
        fetchParkTaxes();
    }, [park_id, state?.refresh])

    return (
        <>
            <div>
                <div style={{ display  : 'flex', alignItems : 'center', }}>
                    <div style={{ marginLeft : 'auto', }}>
                        <Button onClick={() => setState(state => ({...state, create_modal : true, modal : (state?.modal + 1),}))} icon={<PlusOutlined />}>Tax</Button>
                    </div>
                </div>
                <div style={{ marginTop : 12, }}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Space size={20} direction='vertical' style={{ width : '100%', }}>
                            {
                                state?.park_taxes?.length > 0 ?
                                (
                                    <>
                                        {
                                            state?.park_taxes?.map((park_tax, index) => {
                                                const { park_tax_id, name, percentage, value, is_calculated_by_total, } = park_tax;
                                                return (
                                                    <Card
                                                    className='card-shadow'
                                                    styles={{ body : { padding : 12, } }}
                                                    style={{ border: 'none', }}
                                                    >
                                                        <div>
                                                            <div style={{ textAlign : 'start', display : 'flex', alignItems : 'center', }}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{ marginLeft : 'auto', }}>
                                                                    <Button.Group>
                                                                        <Button
                                                                        icon={<EditOutlined style={{color : 'var(--secondary-text-color)',}} />}
                                                                        size='small'
                                                                        style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}}
                                                                        onClick={() => openEditModal(park_tax_id)}
                                                                        />
                                                                        <Popconfirm
                                                                        onConfirm={() => deleteParkTax(park_tax_id)}
                                                                        title={`Are you confirm to delete tax?`}
                                                                        description={name}
                                                                        okButtonProps={{ loading : state?.delete_loading, }}
                                                                        >
                                                                            <Button size='small' icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                                                        </Popconfirm>
                                                                    </Button.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ textAlign : 'start', marginTop : 8, }}>
                                                                <Space>
                                                                    <Tag color='var(--secondary-button-color)' style={{ color : 'var(--secondary-text-color)',}}>{`Percentage : ${percentage}%`}</Tag>

                                                                    {
                                                                        value && <Tag color='var(--secondary-button-color)' style={{ color : 'var(--secondary-text-color)',}}>{`Value : ${state?.park?.currency?.symbol}${value}`}</Tag>
                                                                    }

                                                                    {
                                                                        is_calculated_by_total && (
                                                                            <>
                                                                                <Tag color='blue'>{`Calculated by total amount`}</Tag>
                                                                            </>
                                                                        )
                                                                    }
                                                                </Space>

                                                            </div>

                                                            {
                                                                !is_calculated_by_total && (
                                                                    <>
                                                                        <div style={{ marginTop: 12, }}>
                                                                            <div style={{ textAlign : 'start', }}>
                                                                                <PageHeaderComponent title={(
                                                                                    <>
                                                                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Ticket Type and Variant</span>
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div style={{ marginTop : 8, }}>
                                                                                <TaxApplicableTicketTable park_tax={park_tax} ticket_user_types={state?.ticket_user_types} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginTop: 12, }}>
                                                                            <div style={{ textAlign : 'start', }}>
                                                                                <PageHeaderComponent title={(
                                                                                    <>
                                                                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Add Ons</span>
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div style={{ marginTop : 8, }}>
                                                                                <TaxApplicableAddOnTable park_tax={park_tax} add_ons={state?.add_ons} modal_visible={state?.modal} />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div style={{ padding : 12, }}>
                                            <div>
                                                <Empty style={{margin : 12,}} description={"No tax"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                            <div>
                                                <Button onClick={() => setState(state => ({...state, create_modal : true, modal : (state?.modal + 1),}))} icon={<PlusOutlined />}>Tax</Button>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </Space>
                    </Spin>
                </div>
            </div>

            <Modal
            title="New Tax"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : null, modal : (state?.modal + 1), }));
                save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={1000}
            style={{ top : 40, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                layout='vertical'
                onFinish={saveParkTax}
                >
                    <AddParkTaxModal form={save_form} ticket_user_types={state?.ticket_user_types} park={state?.park}  />
                </Form>
            </Modal>

            <Modal
            title="Edit Tax"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1), }));
                update_form?.resetFields();
            }}
            onOk={() => update_form.submit()}
            width={1000}
            style={{ top : 40, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={update_form}
                layout='vertical'
                onFinish={updateParkTax}
                >
                    <EditParkTaxModal form={update_form} park_tax_id={state?.edit_modal} ticket_user_types={state?.ticket_user_types} park={state?.park} modal_visible={state?.modal} />
                </Form>
            </Modal>
        </>
    );
}

export default TaxLayout;