import React, { useState } from 'react';
import RoleList from './role_list';
import { SearchOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Input, Space } from 'antd';

const RoleLayout = () => {
    const { innerWidth } = window;
    const [filter, setFilter] = useState({

    });
    const [timer, setTimer] = useState(null);

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            // changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    // const changeFilter = (key, value) => {
    //     let generate_filter = default_filter;
    //     generate_filter[key] = value;
        
    //     setFilter(filter => ({...filter, page : 1,}));
    //     const queryString = serviceHelper?.queryString(generate_filter);
    //     window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    // }

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Role'} />
                </div>

                <div>
                    <Input
                    onChange={(e) => doneTyping(e.target.value)}
                    name="search"
                    defaultValue={filter?.search}
                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                    placeholder='Search Role'
                    style={{minWidth: '100%',}}
                    allowClear
                    />
                </div>

                <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                    <div>
                        <div className='filter-panel' style={{textAlign:'start'}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}></Space>
                        </div>
                    </div>
                </div>

                <RoleList filter={filter} />
            </div>
        </>
    );
}

export default RoleLayout;