import { CopyFilled, CopyOutlined } from '@ant-design/icons';
import { Button, Card, notification, Space } from 'antd';
import React, { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const DefaultPromotionCodeLink = ({ promotion_code, }) => {
    const url = useMemo(() => {
        let g_url = '';
        if(promotion_code){
            g_url = `${promotion_code?.parks?.base_url}?pc=${promotion_code?.promo_code}`;
        }
        return g_url;
    }, [promotion_code])

    const onCopy = () => {
        notification.success({
            message : 'Copied!',
            description : 'Default Promotion Code Link Copied Successfully',
            placement : 'bottomLeft',
        })
    }
    
    return (
        <>
            <Card style={{height : '100%',}}>
                <div>
                    <div style={{textAlign:'start'}}>
                        <span style={{fontWeight:500,}}>Default Promotion Code Link</span>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Space align='center'>
                            <a target='_blank' href={url}>
                                {url}
                            </a>
                            <div>
                                <CopyToClipboard text={url} onCopy={onCopy}>
                                    <Button icon={<CopyOutlined />} size='small' style={{color : 'var(--secondary-text-color)'}} />
                                </CopyToClipboard>
                            </div>
                        </Space>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default DefaultPromotionCodeLink;