import { Button, Calendar, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

const ImportPromoDate = ({ form, }) => {
    const [state, setState] = useState({
        selected_month : dayjs()?.month(),
        selected_year : dayjs()?.year(),

        refresh : 0,
    })

    const [updated, setUpdated] = useState([]); // to set state on changed toggle

    const toggleChanges = (is_applicable, visit_date) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.visit_date == visit_date);
        if(exisiting_updated_date){
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            updated_data?.push({ is_applicable, visit_date, });
        }
        
        form?.setFieldsValue({ visit_dates : updated_data, });
        setState(state => ({...state, refresh : (state?.refresh + 1)}))
        setUpdated(updated_data);
    }

    const toggleAll = () => {
        const startDate = dayjs(`${state?.selected_year}-${(state?.selected_month + 1)}-01`);
        const daysInMonth = startDate?.daysInMonth();
        const all_days_in_month = Array.from({ length: daysInMonth }, (_, i) => startDate.add(i, 'day').format('YYYY-MM-DD'));
        for(const date of all_days_in_month){
            toggleChanges(true, date);
        }
    }

    return (
        <>
            <div style={{ display : 'flex', }}>
                <div style={{ marginLeft : 'auto', }}>
                    <Button style={{ background : 'var(--secondary-button-color)', }} type='text' size='small' onClick={toggleAll}>Toggle All</Button>
                </div>
            </div>
            <div style={{ marginTop : 12, }}>
                <Calendar
                style={{border : '1px solid #f0f0f0', padding:12, borderRadius:8,}}
                mode='month'
                // onSelect={(e) => e.stopPropagation()}
                onPanelChange={(value, mode) => {
                    const month = dayjs(value)?.month();
                    const year = dayjs(value)?.year();

                    setState(state => ({...state, selected_month : month, selected_year : year,}));
                }}
                cellRender={(current) => {
                    const date = dayjs(current)?.format("YYYY-MM-DD");

                    // check is current month
                    // Get the current year and month
                    const current_year = state?.selected_year;
                    const current_month = state?.selected_month + 1; // Month is 0-indexed, so add 1

                    // Get the year and month components of the date to check
                    const date_to_check = dayjs(current);
                    const year_to_check = date_to_check.year();
                    const month_to_check = date_to_check.month() + 1; // Month is 0-indexed, so add 1

                    // Check if the date is in the current month
                    const is_current_month = (current_year === year_to_check) && (current_month === month_to_check);

                    const is_checked = updated?.find(x => x?.visit_date === date)?.is_applicable;
                    
                    return (
                        <>
                            {
                                is_current_month && (
                                    <>
                                        <Switch
                                        key={state?.refresh}
                                        onChange={(e) => toggleChanges(e, date)}
                                        size='small'
                                        checked={is_checked}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }}
                />
            </div>
        </>
    );
}

export default ImportPromoDate;