import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Button, Card, Col, Form, Image, Input, Row, Space, Spin, Upload, notification } from 'antd';
import { LoadingOutlined, PictureFilled, PictureOutlined, PlusOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import StepNoItem from '../../../helpers/components/step_no_item';
import CoverImage from './cover_image';
import ParkOverview from './park_overview';
import BasicConfiguration from './basic_configuration';
import otherService from '../../../services/config/other.service';

const ParkProfile = ({ park_id, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        park : null,
        refresh : 0,
    })

    const [image, setImage] = useState({
        cover_image : null,
        logo_image : null,
    })

    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (type, file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setImage(image => ({...image, [type] : base64Image, }));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    const fetchPurchaseTypes = async () => {
        const purchaseTypeResponse = await otherService?.getPurchaseTypes();
        const { purchase_types, } = purchaseTypeResponse;
        return purchase_types;
    }

    const updatePark = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, address, logo_image, cover_image, currency : currency_id, payment_gateway : payment_gateway_id, capacity, date_change_price, is_user_detail_required, description, banner_text, purchase_type_capacities, } = form.getFieldsValue();
        
        // set purchase type capacity array
        const purchase_types = await fetchPurchaseTypes();
        const mapped_purchase_type_capacities = purchase_types?.map(purchase_type => {
            const { purchase_type_id } = purchase_type;
            const applicable_purchase_type = purchase_type_capacities?.find(x => x === purchase_type_id);
            const is_applicable = applicable_purchase_type ? 1 : 0;
            return {
                purchase_type_id,
                is_applicable,
            }
        });
        
        const updateParkResponse = await parkService?.updatePark(park_id, {
            name, address, currency_id, payment_gateway_id, capacity, date_change_price, is_user_detail_required, description, banner_text,
            logo_image : logo_image?.file,
            cover_image : cover_image?.file,
            purchase_type_capacities : mapped_purchase_type_capacities,
        }).catch(err => {
            setState(state => ({...state, save_loading : false, }));
        });

        notification.success({
            message : 'Park Updated',
            description : `${state?.park?.park_code}`,
        })
        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1)}));
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const purchaseTypeCapacityResponse = await parkService?.getPurchaseTypeCapacity(park_id);
        const { parks, } = parkResponse;
        const { purchase_type_capacities, } = purchaseTypeCapacityResponse;

        // set values to form
        const { name, address, image : logo_image, cover_image, currency_id, payment_gateway_id, capacity, date_change_price, is_user_detail_required, description, } = parks;
        form.setFieldsValue({
            name,
            address,
            capacity,
            date_change_price,
            is_user_detail_required,
            payment_gateway : payment_gateway_id,
            currency : currency_id,
            purchase_type_capacities : purchase_type_capacities?.map(x => x?.purchase_type_id),
        });
        
        // set state
        setState(state => ({...state,  park : parks, loading : false, }));
        // set image
        setImage(image => ({...image, logo_image : logo_image, cover_image : cover_image, }));
    }

    const removeImage = async (type) => {
        const removeParkImageResponse = await parkService?.removeParkImage(park_id, type);
        setState(state => ({...state, refresh : (state?.refresh + 1),}));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Form
                    form={form}
                    layout='vertical'
                    onFinish={updatePark}
                    >
                        <div>
                            <CoverImage form={form} beforeUpload={beforeUpload} removeImage={removeImage} image={image?.cover_image} />
                        </div>

                        <div style={{marginTop:12}}>
                            <ParkOverview form={form} beforeUpload={beforeUpload} removeImage={removeImage} image={image?.logo_image} park={state?.park} />
                        </div>

                        <div style={{marginTop:12,}}>
                            <BasicConfiguration form={form} park={state?.park} />
                        </div>

                        <div style={{ marginTop: 12, display : 'flex', }}>
                            <div style={{ marginLeft : 'auto', }}>
                                <Form.Item>
                                    <Button loading={state?.save_loading} onClick={() => form.submit()} type="primary" style={{ minWidth : 200, }}>Save</Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </Spin>
        </>
    );
}

export default ParkProfile;