import React, { useEffect, useState } from 'react';
import PromoList from './promo_list';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CaretDownFilled, DownloadOutlined, LoadingOutlined, SearchOutlined, UploadOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, Dropdown, Form, Input, List, Modal, Radio, Space, Spin, notification } from 'antd';
import NewPromoModal from './new_promo_modal';
import promotionService from '../../../services/config/promotion.service';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { set_valid_promo_code } from '../../../actions/config.action';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import affiliateService from '../../../services/config/affiliate.service';
import globalHelper from '../../../helpers/functions/global.helper';
import ImportPromoModal from './import_promo_modal';

const PromoLayout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const configRedux = useSelector(state => state.config);
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [import_form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        generate_modal : false,
        import_modal : false,
        save_loading : false,
        import_loading : false,

        loading : false,

        // filter item
        parks : [],
        affiliates : [],

        refresh : 0,
    })

    const [filter, setFilter] = useState({
        parks : null,
        affiliates : null,
        search : null,
        is_open_date : null,
    });

    const [search, setSearch] = useState({
        parks : null,
        affiliates : null,
    })

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            onCheckChange('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const savePromotionCode = async () => {
        const { name, promo_code, park, percentage, value, max_cap, } = form?.getFieldsValue();
        setState(state => ({...state, save_loading : true, }))
        const savePromoCodeResponse = await promotionService?.savePromoCode({
            name, promo_code, park_id : park, percentage, value, max_cap
        }).catch(err => {
            setState(state => ({...state, save_loading : false, }));
        });

        if(savePromoCodeResponse){
            setTimeout(() => {
                const { promotion_code_id, promo_code : created_promo_code } = savePromoCodeResponse?.save_promotion_code;

                notification?.success({
                    message : 'Promotion Code',
                    description : `${created_promo_code} has been created`
                })

                setState(state => ({...state, save_loading : false, }));
                history.push(`/promo/detail/${promotion_code_id}`)
            }, 500);
        }
    }

    const uploadPromotionCodes = async () => {
        setState(state => ({...state, import_loading : true, }));
        const { park_id, file, ticket_applicable_users, add_ons, visit_dates, } = (import_form?.getFieldsValue())
        const importPromotionCodeResponse = await promotionService?.importPromotionCodes({
            park_id, file, ticket_applicable_users, add_ons, visit_dates,
        })?.catch(err => setState(state => ({...state, import_loading : false, })));

        if(importPromotionCodeResponse){
            const { bulk_insert_promotion_codes, } = importPromotionCodeResponse;
            notification?.success({
                message : 'Import Promotion Code',
                description : `${bulk_insert_promotion_codes?.length} promotion codes has been imported`
            })

            setState(state => ({...state, import_loading : false, import_modal : false, refresh : (state?.refresh + 1), }));
        }
    }

    const closeModal = () => {
        setState(state => ({...state, generate_modal : false, }))
        dispatch(set_valid_promo_code(false));
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues, }));
    }

    const onSearch = (key, value) => {
        setSearch(search => ({...search, [key] : value}));
    }

    const onSearchDoneTyping = async (key, input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            onSearch(key, input);
        }, 300);
        setTimer(newTimer);
    }

    const fetchFilterItems = async () => {
        setState(state => ({...state, loading : true, }));

        // fetch park
        const { affiliates, parks } = search;
        const parkResponse = await parkService.getParkByAuth({ search : parks, });
        const affiliateResponse = await affiliateService?.fetchAffiliate({ search : affiliates, });

        setState(state => ({...state, loading : false, parks : parkResponse?.parks, affiliates : affiliateResponse?.affiliates, }));
    }

    useEffect(() => {
        fetchFilterItems();
    }, [search]);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Promotion Codes'} />
                </div>

                <div>
                    <Input
                    onChange={(e) => doneTyping(e?.target?.value)}
                    // onChange={(e) => doneTyping(e.target.value)}
                    name="search"
                    // defaultValue={filter?.search}
                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                    placeholder='Search Promotion Code'
                    style={{minWidth: '100%',}}
                    allowClear
                    />
                </div>

                <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                    <div>
                        <div className='filter-panel' style={{textAlign:'start'}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input onChange={(e) => onSearchDoneTyping('parks', e?.target?.value)} prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                        </div>
                                        
                                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                            <Checkbox.Group
                                            value={filter?.parks}
                                            onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.parks}
                                                renderItem={(item, index) => {
                                                    const { park_id, name, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={park_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </Spin>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Park {filter?.parks?.length > 0 ? `: ${filter?.parks?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input onChange={(e) => onSearchDoneTyping('affiliates', e?.target?.value)} prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Affiliates' />
                                        </div>
                                        
                                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                            <Checkbox.Group
                                            value={filter?.affiliates}
                                            onChange={(checkedValues) => onCheckChange('affiliates', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.affiliates}
                                                renderItem={(item, index) => {
                                                    const { affiliate_account_id, full_name, email, } = item ?? {};
                                                    const generated_color = globalHelper?.generateFixedColorFromString(email);
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <Space>
                                                                        <div>
                                                                            <Avatar icon={<UserOutlined />} style={{background : generated_color, }} />
                                                                        </div>
                                                                        <div>
                                                                            <div>
                                                                                <span>{full_name}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </Space>
                                                                    
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={affiliate_account_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </Spin>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Affiliates {filter?.affiliates?.length > 0 ? `: ${filter?.affiliates?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                    </div>
                                </FilterBullet>
                                <FilterBullet
                                popover={(
                                    <>
                                        <div>
                                            <Radio.Group
                                            value={filter?.is_open_date}
                                            onChange={(e) => onCheckChange('is_open_date', e?.target?.value)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={[{is_open_date : true, label : 'Yes',}, {is_open_date : false, label : 'No',}]}
                                                renderItem={(item, index) => {
                                                    const { label, is_open_date, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{label}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Radio
                                                                    value={is_open_date}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Radio.Group>
                                        </div>
                                        <Button onClick={() => filter?.is_open_date != null && setFilter(filter => ({...filter, is_open_date : null, }))} size='small' type='text' style={{ width : '100%', color : 'var(--secondary-text-color)', }}>Reset</Button>
                                    </>
                                )}
                                >
                                    <diiv>
                                        <Space>
                                            <div>
                                                <span>Is Open Date {filter?.is_open_date != null && `: ${(filter?.is_open_date ? 'Yes' : 'No')}`}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                    </diiv>
                                </FilterBullet>
                            </Space>
                        </div>
                    </div>

                    <div style={{marginLeft:'auto'}}>
                        <Space>
                            <Button onClick={() => setState(state => ({...state, import_modal : true, }))} icon={<UploadOutlined />}>Import Promotion Codes</Button>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, generate_modal : true,}))}
                            >
                                New Promotion Code
                            </Button>
                        </Space>
                    </div>
                </div>

                <div style={{marginTop:12,}}>
                    <PromoList key={state?.refresh} filter={filter} />
                </div>
            </div>


            {/* Create New Ticket Modal */}
            <Modal
            open={state?.generate_modal}
            onCancel={closeModal}
            title="New Promotion Code"
            // width={800}
            confirmLoading={state?.save_loading}
            onOk={() => form.submit()}
            okButtonProps={{
                disabled : !configRedux?.is_valid_promo_code,
            }}
            >
                <Form
                onFinish={savePromotionCode}
                form={form}
                layout='vertical'
                >
                    <NewPromoModal form={form} />
                </Form>
            </Modal>

            {/* Import Promotion Code Modal */}
            <Modal
            open={state?.import_modal}
            confirmLoading={state?.import_loading}
            title="Import Promotion Codes"
            onCancel={() => setState(state => ({...state, import_modal : false, }))}
            onOk={() => import_form?.submit()}
            width={800}
            centered
            style={{ top : 20, }}
            >
                <Form
                form={import_form}
                onFinish={uploadPromotionCodes}
                >
                    <ImportPromoModal form={import_form} />
                </Form>
            </Modal>
        </>
    );
}

export default PromoLayout;